import serviceAxios from "../index";
import goods from "./goods.js";

export default {
  ...goods,
};

// 新闻列表
export const getNews = (params, data) => {
  return serviceAxios({
    url: "/no-auth/dynamics/list",
    method: "post",
    params,
    data,
  });
};
// 增加浏览量
export const addLook = (id) => {
  return serviceAxios({
    url: "/no-auth/dynamics/view/" + id,
    method: "get",
  });
};
// 职位列表
export const joinUs = (data) => {
  return serviceAxios({
    url: "/no-auth/join/list",
    method: "post",
    data,
  });
};
// 联系我们
export const contatct = (data) => {
  return serviceAxios({
    url: "/no-auth/contact",
    method: "post",
    data,
  });
};
//注册
export const register = (data) => {
  return serviceAxios({
    url: "/h5/register",
    method: "post",
    data,
  });
};
//sms短信服务
export const sms = (phone) => {
  return serviceAxios({
    url: "/no-auth/sms/sendAliyun/" + phone,
    method: "get",
  });
};
//短信登录
export const smsLogin = (data) => {
  return serviceAxios({
    url: "/h5/sms/login",
    method: "post",
    data,
  });
};
//密码登录
export const passwordLogin = (data) => {
  return serviceAxios({
    url: "/h5/account/login",
    method: "post",
    data,
  });
};
//获取地区
export const area = () => {
  return serviceAxios({
    url: "/h5/area",
    method: "get",
  });
};
//新增收货地址
export const addAddress = (data) => {
  return serviceAxios({
    url: "/h5/member/address/create",
    method: "post",
    data,
  });
};
//查询收货地址详情
export const addressDetail = (id) => {
  return serviceAxios({
    url: "/h5/member/address/" + id,
    method: "get",
  });
};
//修改收货地址
export const updateAddress = (data) => {
  return serviceAxios({
    url: "/h5/member/address/update",
    method: "put",
    data,
  });
};
//查询收货地址
export const myAddress = (params) => {
  return serviceAxios({
    url: "/h5/member/address/list",
    method: "get",
    params,
  });
};
//删除收货地址
export const deleteAddress = (id) => {
  return serviceAxios({
    url: `/h5/member/address/${id}`,
    method: "delete",
  });
};

//获取用户信息
export const getUserInfo = () => {
  return serviceAxios({
    url: `/h5/member/info`,
    method: "get",
  });
};

//修改密码
export const putPassWord = (data) => {
  return serviceAxios({
    url: "/h5/member/updatePass",
    method: "post",
    data,
  });
};

//查询用户默认地址
export const getDefaultAddress = () => {
  return serviceAxios({
    url: `/h5/member/address/default`,
    method: "get",
  });
};

//用户购物车点击去结算
export const addOrderCheck = (data) => {
  return serviceAxios({
    url: `/h5/order/addOrderCheck`,
    method: "post",
    data,
  });
};

//校验完之后生订单
export const addOrder = (data) => {
  return serviceAxios({
    url: `/h5/order/add`,
    method: "post",
    data,
  });
};

//获取订单列表
export const getOrderList = (params) => {
  return serviceAxios({
    url: `/h5/order/page`,
    method: "get",
    params,
  });
};

//增加一个我的下载
export const addDownloadHistory = (params) => {
  return serviceAxios({
    url: `/h5/member/downloadHistory/add`,
    method: "get",
    params,
  });
};

//查看我的下载列表
export const getDownloadHistory = (params, data) => {
  return serviceAxios({
    url: `/h5/member/downloadHistory/list`,
    method: "post",
    params,
    data,
  });
};

//修改用户信息
export const updateMemberInfo = (data) => {
  return serviceAxios({
    url: `/h5/member/update`,
    method: "post",
    data,
  });
};

//修改购物车
export const modifyCart = (data) => {
  return serviceAxios({
    url: `/h5/cart/modify`,
    method: "post",
    data,
  });
};

//删除购物车中的商品
export const removeCart = (data) => {
  return serviceAxios({
    url: `/h5/cart/remove`,
    method: "delete",
    data,
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

//查询资源列表
export const getProductDocument = (params, data) => {
  return serviceAxios({
    url: `/no-auth/productDocument/list`,
    method: "post",
    params,
    data,
  });
};

// 支付接口
export const orderPay = (data) => {
  return serviceAxios({
    url: `/h5/order/orderPay`,
    method: "post",
    data,
  });
};

//取消订单接口
export const orderCancel = (data) => {
  return serviceAxios({
    url: `/h5/order/orderCancel`,
    method: "post",
    data,
  });
};

//获取所有产品id和产品分类id
export const getGoodsIds = () => {
  return serviceAxios({
    url: `/no-auth/product/productIdList`,
    method: "get",
  });
};

//查询订单状态
export const queryOrderStatus = (params) => {
  return serviceAxios({
    url: `/h5/order/queryOrder`,
    method: "get",
    params,
  });
};

//申请售后
export const applyRefund = (data) => {
  return serviceAxios({
    url: `/h5/order/applyRefund`,
    method: "post",
    data,
  });
};

//查询退款状态
export const queryRefundStatus = (params) => {
  return serviceAxios({
    url: `/h5/order/queryRefund`,
    method: "get",
    params,
  });
};

//订单确认收货
export const orderComplete = (params) => {
  return serviceAxios({
    url: `/h5/order/orderComplete`,
    method: "get",
    params,
  });
};

//查看订单详情
export const getOrderDetail = (params) => {
  return serviceAxios({
    url: `/h5/order/orderDetail`,
    method: "get",
    params,
  });
};

//查询售后详情
export const getRefundOrderDetail = (params) => {
  return serviceAxios({
    url: `/h5/order/refundOrderDetail`,
    method: "get",
    params,
  });
};

//退货物寄件
export const returnMsg = (data) => {
  return serviceAxios({
    url: `/h5/order/returnMsg`,
    method: "post",
    data,
  });
};

//添加评论
export const addComment = (data) => {
  return serviceAxios({
    url: `/h5/order/addComment`,
    method: "post",
    data,
  });
};

//添加发票信息
export const addInvoice = (data) => {
  return serviceAxios({
    url: `/h5/invoice/addInvoice`,
    method: "post",
    data,
  });
};

//积分商城兑换商品
export const orderExchange = (data) => {
  return serviceAxios({
    url: `/h5/order/exchange`,
    method: "post",
    data,
  });
};

//积分商城下单前校验
export const addIntegralordercheck = (data) => {
  return serviceAxios({
    url: `/h5/order/addIntegralOrderCheck`,
    method: "post",
    data,
  });
};

//上传文件
export const uploadSingle = (data) => {
  return serviceAxios({
    url: `/h5/file/upload`,
    method: "post",
    data,
    headers: {
      contentType: "multipart/form-data",
    },
  });
};

//批量上传文件
export const uploadFiles = (data) => {
  return serviceAxios({
    url: `/h5/file/uploadFiles`,
    method: "post",
    data,
    headers: {
      contentType: "multipart/form-data",
    },
  });
};

export const getIntegralDetail = (params) => {
  return serviceAxios({
    url: `/h5/integralDetail`,
    method: "post",
    params,
  });
};

//获取是否签到
export const getIfSign = () => {
  return serviceAxios({
    url: `/h5/ifSign`,
    method: "get",
  });
};

//立即签到
export const sign = () => {
  return serviceAxios({
    url: `/h5/signIn`,
    method: "get",
  });
};

//新增开票信息
export const addInvoiceInfo = (data) => {
  return serviceAxios({
    url: `/h5/memberInvoice/create`,
    method: "post",
    data,
  });
};

//删除开票信息
export const delInvoice = (id) => {
  return serviceAxios({
    url: `/oms/invoice/${id}`,
    method: "post",
  });
};

//更新开票信息
export const updateInvoice = (data) => {
  return serviceAxios({
    url: `/h5/memberInvoice/update`,
    method: "put",
    data,
  });
};

//查询开票信息列表
export const getInvoiceList = () => {
  return serviceAxios({
    url: `/h5/memberInvoice/list`,
    method: "get",
  });
};

//设置默认开票信息
export const setDefaultInvoice = (id) => {
  return serviceAxios({
    url: `/h5/memberInvoice/setDefault/${id}`,
    method: "get",
  });
};

//删除开票信息
export const deleteInvoice = (id) => {
  return serviceAxios({
    url: `/h5/memberInvoice/remove/${id}`,
    method: "delete",
  });
};

//获取公司动态详情信息
export const getCompanyDynamicsDetail = (id) => {
  return serviceAxios({
    url: `/no-auth/dynamics/detail/${id}`,
    method: "get",
  });
};

//获取文章精选列表
export const getLiterature = (params, data) => {
  return serviceAxios({
    url: `/no-auth/literatureIndex/list`,
    method: "post",
    params,
    data,
  });
};

//获取文章精选列表
export const getJoinAddressList = (params, data) => {
  return serviceAxios({
    url: `/no-auth/joinAddress/list`,
    method: "post",
    params,
    data,
  });
};
